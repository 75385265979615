import { MessageOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { StoreContext } from "../../store";
import TopHeader from "./../stories/Header/AdminHeader";
import Footer from "./Footer";
import Header from "./Header";
import Side from "./Side";
import SideBar from "./SideBar";
const { Content } = Layout;

export const Admin = ({ component }) => {
  const WrappedComponent = component;
  return class PP extends Component {
    render() {
      return (
        <Layout style={{ minHeight: "calc(100vh / 1)" }}>
          <TopHeader />
          <Layout className="site-layout">
            <Side />
            <Content className="layout--admin">
              <WrappedComponent {...this.props} />
            </Content>
          </Layout>
        </Layout>
      );
    }
  };
};

export const Sales = ({ component }) => {
  const WrappedComponent = component;
  return class CC extends Component {
    render() {
      return (
        <Layout style={{ minHeight: "calc(100vh / 1)" }}>
          <Layout className="site-layout">
            <Content style={{ padding: 10 }}>
              <WrappedComponent {...this.props} />
            </Content>
            <Footer />
          </Layout>
        </Layout>
      );
    }
  };
};

export const Customer = ({ verticalProps, middleProps, component }) => {
  const WrappedComponent = component;

  return class extends Component {
    //   const { user = {} } = useContext(StoreContext);
    static contextType = StoreContext;

    componentDidMount() {
      const { user } = this.context;

      if (user && user.id && user.account) {
        console.log("add chat");
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.innerHTML = `    
        Genesys("command", "Database.set", {
        messaging: {
          customAttributes: {
            name: "${user.username +
          " (" +
          user.account.id +
          "/" +
          user.account.title +
          ")"
          }",
            email: "${user.email}",
            phone: "${user.phone}",
            userId: "${user.id}",
          }
        }
      });
  
      function toggleMessenger(){
        Genesys("command", "Messenger.open", {},
          function(o){},  // if resolved
          function(o){    // if rejected
            Genesys("command", "Messenger.close");
          }
        );
      }

      document.querySelector("#custom-launcher").onclick = function() {toggleMessenger();};
      

      `;
        // console.log(s);
        this.instance.appendChild(s);
      }
    }

    render() {
      //  // console.log("render index");
      return (
        <Fragment>
          <div
            className="div-horizontal"
            style={{ height: "calc(100vh / 1)" }}
            ref={(el) => (this.instance = el)}
          >
            <SideBar />

            <div style={{ flexGrow: 1, overflow: "auto" }}>
              <Header verticalProps={verticalProps} middleProps={middleProps} />
              <Layout>
                <Content
                  style={{
                    //  display: "flex",
                    overflow: "auto",
                    margin: 0,
                    minHeight: "calc((100vh / 1) - 85px)",
                  }}
                >
                  <WrappedComponent {...this.props} />
                </Content>
                <Footer />
              </Layout>
            </div>
          </div>

          <div className="float-div">
            <Link
              className="float bounce"
              to={{
                pathname: "https://wa.me/+212661947881",
              }}
              target="_blank"
            >
              <WhatsAppOutlined className="float-icon" />
            </Link>

            <span className="float chat bounce" id="custom-launcher">
              <MessageOutlined className="float-icon" />
            </span>
          </div>
        </Fragment>
      );
    }
  };
};
