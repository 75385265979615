import { LogoutOutlined, SecurityScanOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button, Icon, Tabs, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import GlobalSearch from "../../../screens/Customer/Catalog/GlobalSearch";
import Logo from "../../assets/images/dynamique/logo.png";
import LogoPlus from "../../assets/images/dynamique/logoPlus.png";

import { useQuery } from "@apollo/react-hooks";
import {
  faBuilding,
  faFileContract,
  faFileInvoiceDollar,
  faHistory,
  faSearch,
  faUser,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { dequal } from "dequal";
import { memo } from "react";
import ReactPlayer from "react-player";
import { useLocation } from "react-router-dom";
import { SideBarContext } from "../../../Context/SideBar";
import {
  HistoriqueEquipementier,
  HistoriqueReference,
  HistoriqueVehicule,
  HistoriqueVin,
} from "../../../screens/Customer/Catalog/GlobalSearch/historiqueRecherche";
import { VIDEO_BY_PATHNAME } from "../../../utils/constants";
import { CART_ITEMS } from "../../graphql/queries/cart-items";
import { WISH_LIST_ITEMS } from "../../graphql/queries/wish-list-items";
import ModalVideoDemo from "./ModalVideoDemo";
const { TabPane } = Tabs;
const SideBar = () => {
  const { sideBarStatut, setSideBarStatut } = useContext(SideBarContext);

  const location = useLocation();
  console.log(
    "path",
    "../../assets/videos/" + VIDEO_BY_PATHNAME(location.pathname)
  );

  const [importedComponent, setImportedComponent] = useState(null);
  const [visibleModalVideoDemo, setVisibleModalVideoDemo] = useState(false);

  useEffect(() => {
    const importComponent = async () => {
      const module = await import(
        "../../assets/videos/" + VIDEO_BY_PATHNAME(location.pathname)
      );
      const video = module.default;
      console.log("video change", video);

      setImportedComponent(video);
    };

    importComponent();
  }, [location.pathname]);

  const {
    collapsed,
    selectedTabNavigation,
    selectedTabRechercheHistorique,
    selectedTabRecherche,
    selectedTabHistorique,
  } = sideBarStatut;

  /* const { data: dataBanners, loading: loadingBanners } = useQuery(
    QUERY_BANNERS,
    {
      variables: {
        bannerInput: {
          format: "HOME_PAGE",
        },
      },
    }
  ); */

  const toggleCollapsed = () => {
    setSideBarStatut("collapsed", !collapsed);
  };

  useEffect(() => {
    // console.log("render SideBar");
  }, []);

  // console.log("render SideBar");

  const MenuComponent = ({ iconSize, collapsed }) => (
    <Menu>
      <MenuItem
        icon={<Icon style={{ fontSize: iconSize }} type="home" />}
        component={<Link to="/" />}
      >
        Accueil
      </MenuItem>

      <MenuItem
        icon={<Icon style={{ fontSize: iconSize }} type="shop" />}
        component={<Link to="/catalog" />}
      >
        Catalogue
      </MenuItem>

      <MenuItem
        icon={<Icon style={{ fontSize: iconSize }} type="import" />}
        component={<Link to="/entries" />}
      >
        Entrées
      </MenuItem>

      <MenuItem
        icon={<Icon style={{ fontSize: iconSize }} type="tags" />}
        component={<Link to="/promotions" />}
      >
        Promotions
      </MenuItem>

      <MenuItem
        icon={
          <span style={{ position: "relative" }}>
            <Icon style={{ fontSize: iconSize }} type="shopping-cart" />
            {queryCart?.data?.cartItems && collapsed ? (
              <Badge
                style={{ position: "absolute", top: -22, right: -29 }}
                count={queryCart.data.cartItems.count}
              />
            ) : null}
          </span>
        }
        component={<Link to="/cart-items" />}
      >
        <div className="div-horizontal" style={{ width: "100%" }}>
          <span className="growed">Panier</span>
          {queryCart?.data?.cartItems ? (
            <Badge count={queryCart.data.cartItems.count} />
          ) : null}
        </div>
      </MenuItem>

      <MenuItem
        icon={
          <span style={{ position: "relative" }}>
            <Icon style={{ fontSize: iconSize }} type="heart" />
            {query?.data?.wishListItems && collapsed ? (
              <Badge
                style={{ position: "absolute", top: -22, right: -29 }}
                count={query.data.wishListItems.count}
              />
            ) : null}
          </span>
        }
        component={<Link to="/wish-list-items" />}
      >
        <div className="div-horizontal" style={{ width: "100%" }}>
          <span className="growed">Favoris</span>
          {query?.data?.wishListItems ? (
            <Badge count={query.data.wishListItems.count} />
          ) : null}
        </div>
      </MenuItem>

      <SubMenu
        label="Historique"
        icon={<FontAwesomeIcon icon={faHistory}></FontAwesomeIcon>}
      >
        <MenuItem
          icon={<FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>}
          component={<Link to="/recherches" />}
        >
          <div className="div-horizontal" style={{ width: "100%" }}>
            <span className="growed">Recherches</span>
            <Badge count={"NEW"} />
          </div>
        </MenuItem>

        {/*  <MenuItem
          icon={<Icon style={{ fontSize: iconSize }} type="frown" />}
          component={<Link to="/retours" />}
        >
          <div className="div-horizontal" style={{ width: "100%" }}>
            <span className="growed">Retours</span>
            <Badge count={"NEW"} />
          </div>
        </MenuItem> */}

        <MenuItem
          icon={<FontAwesomeIcon icon={faFileContract}></FontAwesomeIcon>}
          component={<Link to="/achats" />}
        >
          <div className="div-horizontal" style={{ width: "100%" }}>
            <span className="growed">Achats</span>
            <Badge count={"NEW"} />
          </div>
        </MenuItem>

        <MenuItem
          icon={<FontAwesomeIcon icon={faFileContract}></FontAwesomeIcon>}
          component={<Link to="/orders" />}
        >
          Commandes
        </MenuItem>

        <MenuItem
          icon={<FontAwesomeIcon icon={faFileInvoiceDollar}></FontAwesomeIcon>}
          component={<Link to="/invoices" />}
        >
          Factures
        </MenuItem>

        <MenuItem
          icon={<FontAwesomeIcon icon={faFileContract}></FontAwesomeIcon>}
          component={<Link to="/assets" />}
        >
          Avoirs
        </MenuItem>

        <MenuItem
          icon={<FontAwesomeIcon icon={faFileContract}></FontAwesomeIcon>}
          component={<Link to="/quotes" />}
        >
          Devis
        </MenuItem>

        <MenuItem
          icon={<FontAwesomeIcon icon={faBuilding}></FontAwesomeIcon>}
          component={<Link to="/statements" />}
        >
          Relevés
        </MenuItem>

        <MenuItem
          icon={<Icon style={{ fontSize: iconSize }} type="frown" />}
          component={<Link to="/sav" />}
        >
          Réclammations
        </MenuItem>

        <MenuItem
          icon={<Icon style={{ fontSize: iconSize }} type="warning" />}
          component={<Link to="/profile/supports" />}
        >
          Rappels
        </MenuItem>
      </SubMenu>

      <SubMenu
        label="Gestionnaire de compte"
        icon={<FontAwesomeIcon icon={faUser}></FontAwesomeIcon>}
      >
        <MenuItem
          icon={<FontAwesomeIcon icon={faUserCircle}></FontAwesomeIcon>}
          component={<Link to="/profile/me" />}
        >
          Mon compte
        </MenuItem>

        <MenuItem
          icon={<SecurityScanOutlined />}
          component={<Link to="/profile/reset-password" />}
        >
          Mettre à jour mon mot de passe
        </MenuItem>

        <MenuItem
          icon={<LogoutOutlined />}
          component={<span onClick={() => { }} />}
        >
          Se déconnecter
        </MenuItem>
      </SubMenu>
      <MenuItem
        icon={<Icon style={{ fontSize: iconSize }} type="customer-service" />}
        component={<Link to="/contact-us" />}
      >
        Aide & Contact
      </MenuItem>

      {/*  <MenuItem
        icon={<Icon style={{ fontSize: iconSize }} type="shop" />}
        component={<Link to="/web-catalog" />}
      >
        Web Catalogue
      </MenuItem> */}
    </Menu>
  );

  const query = useQuery(WISH_LIST_ITEMS, {
    variables: {
      where: { deleted: false },
    },
  });

  const queryCart = useQuery(CART_ITEMS, {
    variables: {
      where: { deleted: false },
      take: 20,
    },
  });

  // // console.log("render sidebar");

  return (
    <div
      className="div-horizontal"
      style={{ height: "calc(100vh / 1)", position: "relative" }}
    >
      <Sidebar width="350px" collapsed={collapsed}>
        {collapsed ? (
          <>
            <Menu>
              <MenuItem
                component={
                  <Link
                    className="div-vertical center center-justified"
                    style={{
                      padding: "5px",
                      paddingTop: "5px",
                      height: "77px",

                      borderBottom: "1px solid #ddd",
                    }}
                    to={"/"}
                  ></Link>
                }
              >
                <div
                  className="div-vertical center center-justified"
                  style={{ height: "100%" }}
                >
                  <img
                    src={LogoPlus}
                    alt="logo"
                    style={{
                      maxHeight: "40px",
                      maxWidth: "100%",
                      alignSelf: "center",
                    }}
                  />
                </div>
              </MenuItem>
            </Menu>

            <MenuComponent iconSize={22} collapsed={collapsed} />
          </>
        ) : (
          <>
            <Menu>
              <MenuItem
                component={
                  <Link
                    className="div-vertical center center-justified"
                    style={{
                      padding: "5px",
                      paddingTop: "5px",
                      height: "77px",

                      borderBottom: "1px solid #ddd",
                    }}
                    to={"/"}
                  ></Link>
                }
              >
                <div
                  className="div-vertical center center-justified"
                  style={{ height: "100%" }}
                >
                  <img
                    src={Logo}
                    alt="logo"
                    style={{
                      maxHeight: "50px",
                      maxWidth: "100%",
                      alignSelf: "center",
                    }}
                  />
                </div>
              </MenuItem>
            </Menu>
            <div className="div-vertical" style={{ height: "100%" }}>
              <Tabs
                defaultActiveKey={selectedTabNavigation}
                onChange={(activeKey) => {
                  // console.log("change");
                  setSideBarStatut("selectedTabNavigation", activeKey);
                }}
              >
                <TabPane
                  className="tapSwitch"
                  tab={
                    <span className="div-horizontal center">
                      <Icon style={{ fontSize: 22 }} type="file-search" />
                      Recherche
                    </span>
                  }
                  key="1"
                >
                  <Tabs
                    defaultActiveKey={selectedTabRechercheHistorique}
                    type="card"
                    onChange={(activeKey) => {
                      setSideBarStatut(
                        "selectedTabRechercheHistorique",
                        activeKey
                      );
                    }}
                  >
                    <TabPane
                      tab={
                        <span className="div-horizontal center">
                          <Icon style={{ fontSize: 22 }} type="search" />
                          Recherche
                        </span>
                      }
                      key="1"
                    >
                      <GlobalSearch />
                    </TabPane>

                    <TabPane
                      tab={
                        <span className="div-horizontal center">
                          <Icon style={{ fontSize: 22 }} type="history" />
                          Historique
                        </span>
                      }
                      key="2"
                    >
                      <Tabs
                        size="small"
                        className="tab-historique"
                        defaultActiveKey={selectedTabHistorique}
                        onChange={(activeKey) => {
                          setSideBarStatut("selectedTabHistorique", activeKey);
                        }}
                      >
                        <TabPane
                          className="tapSwitch"
                          tab={
                            <span className="div-horizontal center">
                              Référence
                            </span>
                          }
                          key="1"
                        >
                          <HistoriqueReference type={"reference"} />
                        </TabPane>

                        <TabPane
                          className="tapSwitch"
                          tab={
                            <span className="div-horizontal center">
                              Véhciules
                            </span>
                          }
                          key="2"
                        >
                          <HistoriqueVehicule />
                        </TabPane>

                        <TabPane
                          className="tapSwitch"
                          tab={
                            <span className="div-horizontal center">
                              Équipementier
                            </span>
                          }
                          key="3"
                        >
                          <HistoriqueEquipementier />
                        </TabPane>

                        <TabPane
                          className="tapSwitch"
                          tab={
                            <span className="div-horizontal center">VIN</span>
                          }
                          key="4"
                        >
                          <HistoriqueVin />
                        </TabPane>

                        {/*   <TabPane
                        className="tapSwitch"
                        tab={
                          <span className="div-horizontal center">
                            Code moteur
                          </span>
                        }
                        key="5"
                      >
                        <HistoriqueReference type={"codeMoteur"} />
                      </TabPane> */}
                      </Tabs>
                    </TabPane>
                  </Tabs>
                </TabPane>

                <TabPane
                  tab={
                    <span className="div-horizontal center">
                      <Icon style={{ fontSize: 22 }} type="bars" /> Navigation
                    </span>
                  }
                  key="2"
                >
                  <MenuComponent iconSize={22} collapsed={collapsed} />
                </TabPane>
              </Tabs>

              <div
                className="div-vertical"
                style={{
                  margin: "15px",

                  flexGrow: "1",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  className="div-horizontal center"
                  style={{ marginBottom: "8px" }}
                >
                  <label
                    style={{
                      fontWeight: "bold",
                      margin: "0",
                      marginLeft: "5px",
                      flexGrow: 1,
                      fontSize: "1.2rem",
                    }}
                  >
                    Vidéo démonstrative
                  </label>
                  <Tooltip title={"Explorer toutes les vidéos démo"}>
                    <Button
                      type="primary"
                      style={{ padding: "0px 5px" }}
                      onClick={() => {
                        setVisibleModalVideoDemo(true);
                      }}
                    >
                      <Icon style={{ fontSize: "22px" }} type="bars" />
                    </Button>
                  </Tooltip>
                </div>
                <div
                  style={{
                    height: "123px",
                    overflow: "hidden",
                    borderRadius: "5px",
                    //boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    border: "1px solid #eee",
                    zoom: "calc(1 / 1)",
                  }}
                >
                  <ReactPlayer
                    url={importedComponent}
                    width="100%"
                    height="100%"
                    muted={true}
                    playing={false}
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                    controls={true}
                    loop={false}
                  />
                </div>
              </div>
            </div>
            <ModalVideoDemo
              visible={visibleModalVideoDemo}
              setVisible={setVisibleModalVideoDemo}
            />
          </>
        )}
      </Sidebar>

      <Button
        shape="circle"
        size="default"
        icon={collapsed ? "right" : "left"}
        style={{
          position: "absolute",
          right: -15,
          top: 30,
          zIndex: 900,
        }}
        onClick={toggleCollapsed}
      />
    </div>
  );
};

export default memo(SideBar, (prev, next) => dequal(prev, next));
